.serving {
    display: flex;
    flex-direction: column;
    color: #1F3D27;
}

a {
    font-weight: 700;
    text-decoration: none;
    color: #000000;
    padding: 0 !important;
    margin: 0 !important;
}

.serving-title {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 340px) {
    .serving {
        margin: 10px;
        row-gap: 20px;
    }

    .serving-title {
        align-items: flex-start;
        width: 280px;
    }

    .serving-title * {
        margin: 10px 10px;
    }

    .serving-images {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .map-region-image, .roof-working-image {
        width: 95%;
        height: 150px;
    }

    .serving-title h3 {
        font-size: 16px;
    }

    .serving-title p {
        font-size: 10px;
    }
}

@media only screen and (min-width: 341px) and (max-width: 389px) {
    .serving {
        margin: 0;
        row-gap: 20px;
    }

    .serving-title {
        align-items: flex-start;
    }

    .serving-title * {
        margin: 15px 30px;
    }

    .serving-images {
        display: flex;
        align-items: center;
        margin: 20px;
        flex-direction: column;
        justify-content: center;
    }

    .map-region-image, .roof-working-image {
        width: 100%;
        height: 180px;
    }

    .serving-title h3 {
        font-size: 16px;
    }

    .serving-title p {
        font-size: 10px;
    }
}

@media only screen and (min-width: 390px) and (max-width: 767px) {
    .serving {
        margin: 0;
        row-gap: 20px;
    }

    .serving-title {
        align-items: flex-start;
    }

    .serving-title * {
        margin: 15px 30px;
    }

    .serving-images {
        display: flex;
        align-items: center;
        margin: 20px;
        flex-direction: column;
        justify-content: center;
    }

    .map-region-image, .roof-working-image {
        width: 100%;
        height: 250px;
    }

    .serving-title h3 {
        font-size: 24px;
    }

    .serving-title p {
        font-size: 10px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .serving {
        margin: 0;
        row-gap: 30px;
    }

    .serving-title {
        align-items: flex-start;
        justify-content: space-evenly;
    }

    .serving-title * {
        margin: 15px 40px;
    }

    .serving-images {
        display: flex;
        margin: 2% 0;
        align-items: center;
        flex-direction: column;
    }

    .map-region-image, .roof-working-image {
        width: 90%;
        height: 400px;
    }

    .serving-title h3 {
        font-size: 18px;
    }

    .serving-title p {
        width: 80%;
        font-size: 12px;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1279px) {
    .serving {
        margin: 0 80px 64px 80px;
        row-gap: 30px;
    }

    .serving-title {
        width: 100%;
        margin: 0;
    }

    .serving-title * {
        margin: 20px 0;
    }

    .roof-working-image {
        position: absolute;
        width: 856px;
        height: 518px;
        float: left;
        margin-top: 98px;
        z-index: 20;
    }

    .map-region-image {
        position: relative;
        float: right;
        width: 40%;
        height: 50%;
    }

    .serving-title h3 {
        font-size: 32px;
        line-height: 42px;
    }

    .serving-title p {
        width: 80%;
        font-size: 22px;
    }

    .roof-working-image {
        position: absolute;
        width: 40%;
        height: 300px;
        float: left;
        margin-top: 58px;
        z-index: 20;
    }

    .map-region-image {
        position: relative;
        float: right;
        width: 60%;
        height: 400px;
    }
}

@media only screen and (min-width: 1280px) and (max-width: 1439px) {
    .serving {
        margin: 0 80px 50px 80px;
        row-gap: 40px;
    }

    .serving-title {
        width: 1045px;
        margin: 0;
    }

    .serving-title * {
        margin: 20px 0;
    }

    .serving-title h3 {
        font-size: 32px;
        line-height: 42px;
    }

    .serving-title p {
        font-size: 24px;
        line-height: 32px;
    }


    .roof-working-image {
        position: absolute;
        width: 40%;
        height: 320px;
        float: left;
        margin-top: 70px;
        z-index: 20;
    }

    .map-region-image {
        position: relative;
        float: right;
        width: 60%;
        height: 450px;
    }
}

@media only screen and (min-width: 1440px) {
    .serving {
        margin: 0 120px 64px 120px;
        row-gap: 40px;
    }

    .serving-title {
        width: 1045px;
        margin: 0;
    }

    .serving-title * {
        margin: 20px 0;
    }

    .roof-working-image {
        position: absolute;
        width: 856px;
        height: 518px;
        float: left;
        margin-top: 98px;
        z-index: 5;
    }

    .map-region-image {
        position: relative;
        float: right;
        width: 990px;
        height: 700px;
    }

    .serving-title h3 {
        font-size: 32px;
        line-height: 42px;
    }

    .serving-title p {
        font-size: 24px;
        line-height: 32px;
    }

    .roof-working-image {
        position: absolute;
        width: 40%;
        height: 400px;
        float: left;
        margin-top: 98px;
        z-index: 20;
    }

    .map-region-image {
        position: relative;
        float: right;
        width: 60%;
        height: 600px;
    }
}

