.extra {
    display: flex;
}

.extra h3 {
    font-weight: 400;
}

@media screen and (max-width: 389px) {
    .extra{
        flex-direction: column;
        margin: 36px 32px;
        align-items: center;
    }
    .extra img {
        width: 100%;
        height: 200px;
        margin: 0;
    }
    .extra h3 {
        font-family: Poppins-Regular;
        font-weight: 400;
        text-align: center;
        margin: 0 0 24px 0;
        font-size: 20px;
    }
}

@media screen and (min-width: 390px) and (max-width: 426px){
    .extra{
        flex-direction: column;
        margin: 36px 32px;
        align-items: center;
    }
    .extra img {
        width: 326px;
        height: 267px;
        margin: 0;
    }
    .extra h3 {
        font-family: Poppins-Regular;
        font-weight: 400;
        text-align: center;
        margin: 0 0 24px 0;
        font-size: 24px;
    }
}

@media screen and (min-width: 427px) and (max-width: 767px){
    .extra{
        flex-direction: column;
        margin: 60px 40px 40px 40px;
        align-items: center;
    }
    .extra img {
        width: 504px;
        height: 250px;
        margin: 0;
    }
    .extra h3 {
        font-family: Poppins-Regular;
        font-weight: 400;
        text-align: center;
        margin: 0 0 24px 0;
        font-size: 26px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px)  {
    .extra{
        flex-direction: column;
        margin: 60px 40px 40px 40px;
        align-items: center;
    }
    .extra img {
        width: 504px;
        height: 250px;
        margin: 0;
    }
    .extra h3 {
        font-family: Poppins-Regular;
        width: 504px;
        font-weight: 400;
        text-align: center;
        margin: 0 0 24px 0;
        font-size: 26px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1279px){
    .extra{
        flex-direction: row-reverse;
        margin: 60px 60px 40px 60px;
        align-items: center;
    }
    .extra img {
        width: 504px;
        height: 250px;
        margin: 0;
    }
    .extra h3 {
        font-family: Poppins-Regular;
        font-weight: 400;
        text-align: center;
        margin: 0 0 0 60px;
        font-size: 26px;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1439px){
    .extra{
        flex-direction: row-reverse;
        margin: 64px 80px 64px 80px;
        align-items: center;
    }
    .extra img {
        width: 604px;
        height: 300px;
        margin: 0;
    }
    .extra h3 {
        font-family: Poppins-Regular;
        font-weight: 400;
        text-align: center;
        margin: 0 0 0 60px;
        font-size: 26px;
    }
}

@media screen and (min-width: 1440px) and (max-width: 1679px){
    .extra{
        flex-direction: row-reverse;
        margin: 64px 120px 64px 120px;
        align-items: center;
    }
    .extra img {
        width: 704px;
        height: 380px;
        margin: 0;
    }
    .extra h3 {
        font-family: Poppins-Regular;
        font-weight: 400;
        text-align: center;
        margin: 0 0 0 50px;
        font-size: 28px;
    }
}

@media screen and (min-width: 1680px){
    .extra{
        flex-direction: row-reverse;
        margin: 64px 120px 64px 120px;
        align-items: center;
    }
    .extra img {
        width: 984px;
        height: 525px;
        margin: 0;
    }
    .extra h3 {
        font-family: Poppins-Regular;
        font-weight: 400;
        text-align: center;
        margin: 0 0 0 60px;
        font-size: 36px;
    }
}







