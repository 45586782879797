.form-container {
    background-color: white;
    border-radius: 6px;
}

.form-title h3 {
    font-family: Poppins-Medium, sans-serif;
    font-weight: 500;
    margin: 0;
}

.form-description {
    display: flex;
    align-items: center;
}

.form-description p {
    font-weight: 400;
}

.form-container .email-form {
    display: flex;
    flex-direction: column;
    background-color: #203E28;
    justify-content: center;
    align-items: center;
}

.form-container .email-form input, .form-container .email-form textarea {
    border-radius: 6px;

}

.email-form button {
    background-color: #e2f2a8;
    border-radius: 6px;
    color: #203e28;
}

    .form-container {
    }

    .form-description {
        margin: 12px 32px;
    }

    .form-description p {
        font-size: 12px;
        margin: 0;
    }

    .form-container .form-title h3 {
        font-size: 20px;
    }

    .form-container .form-title {
        background-color: #E2F2A8;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
    }



.email-form input {
    height: 35px;
    margin: 14px;
    width: 90%;
    padding: 0 16px;
    font-size: 14px;
}

    .email-form textarea {
        height: 100px;
        width: 90%;
        margin: 14px;
        padding: 16px;
        font-size: 16px;
    }

    .email-form input.error::placeholder, .email-form textarea.error::placeholder {
        font-size: 14px;
    }

    .email-form button {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 600;
        width:90%;
        margin:14px;

    }

.p-error {
    color: #e24c4c !important;
    padding-left: 20px;
}