.header {
    display: flex;
    flex-direction: column;
    font-family: Poppins-Regular, sans-serif;
}

.header-title h5 {
    font-family: Poppins-Medium;
    margin: 0;
}

.header-menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.header-buttons {
    font-family: Poppins-Regular, sans-serif;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    /*justify-content: space-between;*/
}

.header-buttons a.phone {
    text-decoration: none;
    color: #203E28;
    font-weight: 400;
}

.header-buttons button {
    border: 0;
    font-family: Poppins-Medium, sans-serif;
    background-color: #E2F2A8;
    color: #203E28;
    padding: 0px;
    border-radius: 6px;
}

.header-menu .header-logo p {
    font-weight: 400;
}

@media screen and (min-width: 279px) and (max-width: 340px) {
    .header {
        margin-bottom: 12px;
    }

    .header-title h5 {
        font-size: 10px;
        font-weight: 400;
        margin: 15px 0;
    }

    .header-menu {
        margin: 0 12px;
    }

    .header-menu .header-logo img {
        height: 36px;
        width: 82px;
    }

    .header-menu .header-logo p {
        width: 100px;
        font-size: 10px;
        line-height: 15px;
        margin: 4px 2px;
    }

    .header-buttons img {
        width: 18px;
        height: 18px;
        margin: 0;
    }

    .header-buttons a.phone {
        display: none;
    }

    .header-buttons button {
        width: 107px;
        margin: 0 0 0 22px;
        height: 40px;
        font-size: 12px;
        font-weight: 500;
    }
}

@media screen and (min-width: 341px) and (max-width: 389px){
    .header {
        margin-bottom: 12px;
    }

    .header-title h5 {
        font-size: 12px;
        font-weight: 400;
        margin: 15px 0;
    }

    .header-menu {
        margin: 0 12px;
    }

    .header-menu .header-logo img {
        height: 46px;
        width: 92px;
    }

    .header-menu .header-logo p {
        width: 115px;
        font-size: 10px;
        line-height: 15px;
        margin: 4px 0;
    }

    .header-buttons img {
        width: 21px;
        height: 21px;
        margin: 0;
    }

    .header-buttons a.phone {
        display: none;
    }

    .header-buttons button {
        width: 137px;
        margin: 0 0 0 22px;
        height: 40px;
        font-size: 16px;
        font-weight: 500;
    }
}

@media screen and (min-width: 390px) and (max-width: 426px) {
    .header {
        margin-bottom: 12px;
    }

    .header-title h5 {
        font-size: 12px;
        font-weight: 400;
        margin: 15px 0;
    }

    .header-menu {
        margin: 0 12px;
    }

    .header-menu .header-logo img {
        height: 46px;
        width: 92px;
    }

    .header-menu .header-logo p {
        width: 120px;
        font-size: 10px;
        margin: 4px 0;
    }

    .header-buttons * {
        margin: 0 11px;
    }

    .header-buttons a.phone {
        display: none;
    }

    .header-buttons button {
        width: 137px;
        margin: 0 0 0 22px;
        height: 40px;
        font-size: 14px;
        font-weight: 500;
    }

}

@media screen and (min-width: 427px) and (max-width: 767px) {
    .header {
        margin-bottom: 14px;
    }

    .header-title h5 {
        font-size: 12px;
        font-weight: 400;
        margin: 15px 0;
    }

    .header-menu {
        margin: 0 20px;
    }

    .header-menu .header-logo img {
        height: 46px;
        width: 92px;
    }

    .header-menu .header-logo p {
        font-size: 10px;
        margin: 4px 0;
    }

    .header-buttons * {
        margin: 0 11px;
    }

    .header-buttons a.phone {
        display: none;
    }

    .header-buttons button {
        width: 150px;
        font-size: 16px;
        margin: 0 0 0 52px;
        height: 40px;
        font-weight: 500;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .header {
        margin-bottom: 12px;
    }

    .header-title h5 {
        font-size: 16px;
        font-weight: 400;
        margin: 15px 0;
    }

    .header-menu {
        margin: 0 40px;
    }

    .header-menu .header-logo img {
        height: 55px;
        width: 105px;
    }

    .header-menu .header-logo p {
        font-size: 10px;
        margin: 4px 0;
    }

    .header-buttons * {
        margin: 0 11px;
    }

    .header-buttons a.phone {
        display: none;
    }

    .header-buttons button {
        width: 200px;
        font-size: 16px;
        margin: 0 0 0 52px;
        height: 40px;
        font-weight: 500;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .header {
        margin-bottom: 18px;
    }

    .header-title {
        display: flex;
        justify-content: center;
    }

    .header-title h5 {
        font-size: 20px;
        margin: 24px 0;
    }

    .header-menu {
        margin: 0 60px;
    }

    .header-menu .header-logo img {
        height: 70px;
        width: 132px;
    }

    .header-menu .header-logo p {
        font-size: 12px;
        margin: 8px 0;
    }

    .header-buttons img {
        display: none;
    }

    .header-buttons * {
        margin: 0 20px;
    }

    .header-buttons .phone {
        font-size: 20px;
        font-weight: 400;
    }

    .header-buttons button {
        width: 220px;
        height: 54px;
        padding: 0;
        font-size: 20px;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
    .header {
        margin-bottom: 18px;
    }

    .header-title {
        display: flex;
        justify-content: center;
    }

    .header-title h5 {
        font-size: 22px;
        margin: 24px 0;
    }

    .header-menu {
        margin: 0 80px;
    }

    .header-menu .header-logo img {
        height: 70px;
        width: 132px;
    }

    .header-menu .header-logo p {
        font-size: 18px;
        line-height: 22px;
        margin: 8px 0;
    }

    .header-buttons img {
        display: none;
    }

    .header-buttons * {
        margin: 0 20px;
    }

    .header-buttons .phone {
        font-size: 22px;
        font-weight: 400;
    }

    .header-buttons button {
        width: 287px;
        height: 60px;
        padding: 0;
        font-size: 22px;
    }
}

@media screen and (min-width: 1440px) and (max-width: 1679px) {
    .header {
        margin-bottom: 20px;
    }

    .header-title {
        display: flex;
        justify-content: center;
    }

    .header-title h5 {
        font-size: 22px;
        margin: 24px 0;
    }

    .header-menu {
        margin: 0 100px;
    }

    .header-menu .header-logo img {
        height: 70px;
        width: 132px;
    }

    .header-menu .header-logo p {
        font-size: 16px;
        line-height: 24px;
        margin: 0;
    }

    .header-buttons img {
        display: none;
    }

    .header-buttons * {
        margin: 0 20px;
    }

    .header-buttons .phone {
        font-size: 24px;
        font-weight: 400;
    }

    .header-buttons button {
        width: 250px;
        height: 64px;
        padding: 0;
        font-size: 24px;
    }
}

@media screen and (min-width: 1680px) {
    .header {
        margin-bottom: 24px;
    }

    .header-title {
        display: flex;
        justify-content: center;
    }

    .header-title h5 {
        font-size: 24px;
        margin: 24px 0;
    }

    .header-menu {
        margin: 0 120px;
    }

    .header-menu .header-logo img {
        height: 70px;
        width: 132px;
    }

    .header-menu .header-logo p {
        font-size: 16px;
        line-height: 24px;
        margin: 8px 0;
    }

    .header-buttons img {
        display: none;
    }

    .header-buttons * {
        margin: 0 20px;
    }

    .header-buttons .phone {
        font-size: 28px;
        font-weight: 400;
    }

    .header-buttons button {
        width: 287px;
        height: 74px;
        padding: 0;
        font-size: 28px;
    }
}
