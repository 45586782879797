@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins'), url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins'), url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins'), url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
}
