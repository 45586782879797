.details {
    display: flex;
}

.card {
    display: flex;
    flex-direction: column;
    border: none !important;
}

@media screen and (max-width: 389px) {
    .details {
        margin: 50px 32px 32px 32px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }

    .details .card {
        width: 247px;
        align-items: center;
        margin: 16px 0;
        justify-content: space-evenly;
    }

    .details .card img {
        width: 100%;
        height: 235px;
    }

    .card-body {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0;
        align-items: center;
    }

    .card-body .card-title {
        margin: 28px 0 16px 0;
        font-size: 20px;
    }

    .card-body .card-text {
        width: 90%;
        font-size: 14px;
    }
}

@media screen and (min-width: 390px) and (max-width: 426px) {
    .details {
        margin: 50px 32px 32px 32px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }

    .details .card {
        width: 247px;
        align-items: center;
        margin: 16px 0;
        justify-content: space-evenly;
    }

    .details .card img {
        width: 100%;
        height: 235px;
    }

    .card-body {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0;
        align-items: center;
    }

    .card-body .card-title {
        margin: 28px 0 16px 0;
        font-size: 20px;
    }

    .card-body .card-text {
        width: 90%;
        font-size: 14px;
    }
}

@media screen and (min-width: 427px) and (max-width: 767px) {
    .details {
        margin: 60px 32px 32px 32px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }

    .details .card {
        width: 300px;
        align-items: center;
        margin: 16px 0;
        justify-content: space-evenly;
    }

    .details .card img {
        width: 100%;
        height: 235px;
    }

    .card-body {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0;
        align-items: center;
    }

    .card-body .card-title {
        margin: 28px 0 16px 0;
        font-size: 22px;
    }

    .card-body .card-text {
        width: 90%;
        font-size: 16px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .details {
        margin: 60px 40px 32px 40px;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }

    .details .card {
        width: 450px;
        align-items: center;
        margin: 16px 0;
        justify-content: space-evenly;
    }

    .details .card img {
        width: 100%;
        height: 350px;
    }

    .card-body {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0;
        align-items: center;
    }

    .card-body .card-title {
        margin: 28px 0 16px 0;
        font-size: 24px;
    }

    .card-body .card-text {
        width: 90%;
        font-size: 18px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .details {
        margin: 80px 60px 64px 60px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }

    .details .card {
        width: 220px;
        height: 435px;
        align-items: center;
        justify-content: space-evenly;
    }

    .details .card img {
        width: 100%;
        height: 200px;
    }

    .card-body {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0;
        align-items: center;
    }

    .card-body .card-title {
        margin: 28px 0 16px 0;
        font-size: 18px;
    }

    .card-body .card-text {
        width: 90%;
        font-size: 14px;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
    .details {
        margin: 80px 60px 64px 60px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }

    .details .card {
        width: 320px;
        height: 565px;
        align-items: center;
        justify-content: space-evenly;
    }

    .details .card img {
        width: 100%;
        height: 280px;
    }

    .card-body {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0;
        align-items: center;
    }

    .card-body .card-title {
        margin: 28px 0 16px 0;
        font-size: 22px;
    }

    .card-body .card-text {
        width: 80%;
        font-size: 18px;
    }
}

@media screen and (min-width: 1440px) and (max-width: 1679px) {
    .details {
        margin: 120px 60px 64px 60px;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        height: 100%;
    }

    .details .card {
        width: 320px;
        height: 565px;
        align-items: center;
        justify-content: space-evenly;
    }

    .details .card img {
        width: 100%;
        height: 280px;
    }

    .card-body {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 0;
        align-items: center;
    }

    .card-body .card-title {
        margin: 28px 0 16px 0;
        font-size: 22px;
    }

    .card-body .card-text {
        width: 80%;
        font-size: 18px;
    }
}

@media screen and (min-width: 1680px) {
    .details {
        margin: 120px 120px;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }

    .details .card {
        width: 413px;
        height: 620px;
        justify-content: space-between;
    }

    .details .card img {
        width: 100%;
        height: 332px;
    }

    .card-body {
        text-align: center;
        padding: 0;
    }

    .card-body .card-title {
        margin: 28px 0 16px 0;
        font-size: 28px;
    }

    .card-body .card-text {
        font-size: 24px;
    }
}
