.search-location {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: #1F3D27;
    font-family: Poppins-Regular, sans-serif;
    align-items: center;
    height:47px;
}

.search-location h3 {
    color: white;
}

.search-location-modal {
    width: 100%;
}

.search-location-modal-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.search-location-modal-body ul li.other:first-child {
    order: 4;
}

.search-location-modal-body ul {
    list-style-type: none;
    padding: 0;
}

.search-location-modal {
    width: 100%;
}

.search-location button {
    background-color: #e2f2a8;
    border: 0;
    color: #1F3D27;
    border-radius: 6px;
    font-family: Poppins-Medium, sans-serif;
}

.search-location-modal-title .x-mark {
    font-size: 18px;
    font-family: Poppins-Bold, sans-serif;
    font-style: normal;
    font-weight: 700;
}

.search-location-modal-title h3 {
    font-family: Poppins-Bold, sans-serif;
}

.search-location-modal-body {
    overflow: hidden;
}

.search-location-modal ul li {
    font-family: Poppins-Medium, sans-serif;
    width: 100%;
}

.search-location-modal ul ul li {
    padding-left: 30px;
}

.search-location-modal-navigation {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.search-location-modal-body ul li.hover:hover {
    background-color: #e2f2a8;
}

div.button-enquire-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
button.btn-enquire {
    font-family: Poppins-Medium, sans-serif;
    background-color: #e2f2a8;
    color: #203E28;
    border: 0px;
    border-radius: 6px;
    height: 30px;
    width: 200px;
    margin:  15px 0 0 0;
    font-size: 14px;
}

@media screen and (max-width: 340px) {
    .search-location h3 {
        font-size: 12px;
        margin: 15px 5px;
    }

    .search-location button {
        font-size: 12px;
        height: 30px;
        width: 120px;
    }

    .search-location-modal {
        display: flex;
        flex-direction: column;
    }

    .search-location-modal-navigation img {
        width: 100px;
        height: 50px;
    }

    .search-location-modal-body {
        margin-top: 20px;
    }

    .search-location-modal-title {
        gap: 20px;
    }

    .search-location-modal .search-location-modal-title h3 {
        font-size: 14px;
        margin: 0;
    }

    .search-location-modal-body ul li.selected-state, .search-location-modal-body ul li.selected-region {
        font-size: 14px;
        margin: 0 0 5px 0;
        font-family: 'Poppins-Bold', sans-serif;
    }

    .search-location-modal-body ul li {
        margin: 5px 0;
        font-size: 10px;
    }
}

@media screen and (min-width: 341px) and (max-width: 389px) {
    .search-location h3 {
        font-size: 12px;
        margin: 15px 10px;
    }

    .search-location button {
        font-size: 12px;
        height: 25px;
        width: 140px;
    }

    .search-location-modal {
        display: flex;
        width: 180px;
        flex-direction: column;
    }

    .search-location-modal-navigation img {
        width: 100px;
        height: 50px;
    }

    .search-location-modal-body {
        margin-top: 20px;
    }

    .search-location-modal-title {
        gap: 20px;
    }

    .search-location-modal .search-location-modal-title h3 {
        font-size: 14px;
        margin: 0;
    }

    .search-location-modal-body ul li.selected-state, .search-location-modal-body ul li.selected-region {
        font-size: 14px;
        margin: 0 0 5px 0;
        font-family: 'Poppins-Bold', sans-serif;
    }

    .search-location-modal-body ul li {
        margin: 5px 0;
        font-size: 10px;
    }
}

@media screen and (min-width: 390px) and (max-width: 426px) {
    .search-location h3 {
        font-size: 12px;
        margin: 15px 10px;
    }

    .search-location button {
        margin-left: 20px;
        font-size: 12px;
        height: 25px;
        width: 150px;
    }

    .search-location-modal {
        display: flex;
        flex-direction: column;
        width: 200px;
    }

    .search-location-modal-navigation img {
        width: 100px;
        height: 50px;
    }

    .search-location-modal-body {
        margin-top: 20px;
    }

    .search-location-modal-title {
        gap: 20px;
    }

    .search-location-modal .search-location-modal-title h3 {
        font-size: 14px;
        margin: 0;
    }

    .search-location-modal-body ul li.selected-state, .search-location-modal-body ul li.selected-region {
        font-size: 14px;
        margin: 0 0 5px 0;
        font-family: 'Poppins-Bol', sans-serif;
    }

    .search-location-modal-body ul li {
        font-size: 12px;
    }
}

@media screen and (min-width: 427px) and (max-width: 767px) {
    .search-location h3 {
        font-size: 12px;
        margin: 15px 10px;
    }

    .search-location button {
        margin-left: 20px;
        font-size: 12px;
        height: 25px;
        width: 150px;
    }

    .search-location-modal-navigation img {
        width: 100px;
        height: 60px;
    }

    .search-location-modal {
        display: flex;
        flex-direction: column;
        width: 250px;
    }

    .search-location-modal-body {
        height: 100%;
        margin-top: 20px;

    }

    .search-location-modal-title {
        gap: 30px;
    }

    .search-location-modal-body ul li.selected-state, .search-location-modal-body ul li.selected-region {
        font-size: 14px;
        font-family: 'Poppins-Bold', sans-serif;
        margin: 20px 0;
    }

    .search-location-modal .search-location-modal-title h3 {
        font-size: 14px;
        margin: 0;
    }

    .search-location-modal-body ul li {
        font-size: 12px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .search-location h3 {
        font-size: 12px;
        margin: 15px 10px;
    }

    .search-location button {
        margin-left: 20px;
        font-size: 12px;
        height: 25px;
        width: 150px;
    }

    .search-location-modal-navigation img {
        width: 110px;
        height: 60px;
    }

    .search-location-modal-title {
        gap: 20px;
    }

    .search-location-modal {
        display: flex;
        flex-direction: column;
    }

    .search-location-modal-body {
        height: 100%;
        margin-top: 30px;
    }

    .search-location-modal-title {
        gap: 40px;
    }

    .search-location-modal .search-location-modal-title h3 {
        font-size: 16px;
        margin: 0;
    }

    .search-location-modal-body ul li.selected-state, .search-location-modal-body ul li.selected-region {
        font-size: 16px;
        margin: 0 0 15px 0;
        font-family: 'Poppins-Bold', sans-serif;
    }

    .search-location-modal-body ul li {
        margin-bottom: 5px;
        font-size: 14px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .search-location h3 {
        font-size: 14px;
        margin: 15px 40px;
    }

    .search-location button {
        margin-left: 40px;
        font-size: 14px;
        height: 30px;
        width: 250px;
    }

    .search-location-modal {
        display: flex;
        flex-direction: column;
    }

    .search-location-modal-body {
        height: 100%;
        margin-top: 30px;
    }

    .search-location-modal-title {
        gap: 40px;
    }

    .search-location-modal .search-location-modal-title h3 {
        font-size: 18px;
        margin: 0;
    }

    .search-location-modal-body ul li.selected-state, .search-location-modal-body ul li.selected-region {
        font-size: 18px;
        margin: 0 0 15px 0;
        font-family: 'Poppins-Bold', sans-serif;
    }

    .search-location-modal-body ul li {
        margin-bottom: 7px;
        font-size: 16px;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
    .search-location h3 {
        font-size: 14px;
        margin: 15px 40px;
    }

    .search-location button {
        margin-left: 40px;
        font-size: 14px;
        height: 30px;
        width: 250px;
    }

    .search-location-modal {
        display: flex;
        flex-direction: column;
    }

    .search-location-modal-title {
        gap: 40px;
    }

    .search-location-modal-body ul li.selected-state, .search-location-modal-body ul li.selected-region {
        font-size: 22px;
        margin: 0 0 15px 0;
        font-family: 'Poppins-Bold', sans-serif;
    }

    .search-location-modal .search-location-modal-title h3 {
        font-size: 22px;
        margin: 0;
    }

    .search-location-modal-body ul li {
        font-size: 16px;
        margin-bottom: 8px;
    }

    .search-location-modal-body {
        margin-top: 20px;
    }
}

@media screen and (min-width: 1440px) and (max-width: 1679px) {
    .search-location h3 {
        font-size: 14px;
        margin: 15px 40px;
    }

    .search-location button {
        margin-left: 40px;
        font-size: 14px;
        height: 30px;
        width: 250px;
    }

    .search-location-modal {
        display: flex;
        flex-direction: column;
    }

    .search-location-modal-title {
        margin-top: 25px;
    }

    .search-location-modal-title {
        gap: 60px;
    }

    .search-location-modal-body {
        margin-top: 10px;
    }

    .search-location-modal .search-location-modal-title h3 {
        font-size: 24px;
        margin: 0;
    }

    .search-location-modal-body ul li.selected-state, .search-location-modal-body ul li.selected-region {
        font-size: 25px;
        margin: 0 0 15px 0;
        font-family: 'Poppins-Bold', sans-serif;
    }

    .search-location-modal-body ul li {
        font-size: 20px;
        margin: 5px 0;
    }

}

@media screen and (min-width: 1680px) {
    .search-location h3 {
        font-size: 14px;
        margin: 15px 40px;
    }

    .search-location button {
        margin-left: 40px;
        font-size: 14px;
        height: 30px;
        width: 250px;
    }

    .search-location-modal {
        display: flex;
        flex-direction: column;
    }

    .search-location-modal .search-location-modal-title h3 {
        font-size: 24px;
        margin: 0;
    }

    .search-location-modal-title {
        gap: 60px;
    }

    .search-location-modal-body ul li {
        font-size: 20px;
        margin-bottom: 5px;
    }

    .search-location-modal-body ul li.selected-state, .search-location-modal-body ul li.selected-region {
        font-size: 32px;
        margin: 0 0 15px 0;
        font-family: 'Poppins-Bold', sans-serif;
    }

    .search-location-modal-body {
        margin-top: 30px;
        font-size: 24px;
    }
}




