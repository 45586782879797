.footer-container {
    background-color: #203E28;
    display: flex;
}

.footer-links > div > p {
    display: flex;
    color: #ffffff;
}

.footer-links {
    display: flex;
}

.links a {
    text-decoration: none;
    color: #ffff;
}

.links p {
    color: white;
}

.footer-links h3 {
    color: #E2F2A8;
}

.footer .locations ul {
    margin: 0;
    padding: 0;
}

.footer-logo p {
    font-family: Poppins-Medium, sans-serif;
    color: white;
}

.footer-links .opening-hours ul {
    padding: 0;
    margin: 0;
}

.footer-links .opening-hours ul li p {
    color: white;
}

.footer-links .address ul li p {
    color: white;
}

.footer-links .address ul {
    padding: 0;
    margin: 0;

}

.contact-us ul, .locations ul, .locations ul ul {
    margin: 0;
    padding: 0;
}

.contact-us ul li p, .contact-us ul li ul li p {
    color: white;
    margin: 0;
    padding: 0;
}

.locations ul li p, .locations ul li ul li p {
    color: white;
    margin: 0;
    padding: 0;
}

.footer-links .opening-hours ul li p {
    color: white
}

@media screen and (max-width: 389px) {
    .footer-container {
        padding: 4%;
        flex-direction: column;
        justify-content: space-between;
    }

    .footer-logo {
        margin: 0;
    }

    .footer-logo img {
        height: 50px;
        width: 90px;
    }

    .footer-logo p {
        font-size: 12px;
        margin-left: 2px;
    }

    .footer-links > div {
        margin: 0 5px;
    }

    .footer-links {
        flex-direction: column;
        justify-content: space-between;
    }

    .footer-links div {
        margin: 18px 0;
    }

    .footer-links div h3 {
        margin-bottom: 12px;
        font-size: 18px;
    }

    .footer-links div p {
        font-size: 10px;
    }

    .links a {
        font-size: 11px;
    }
}

@media screen and (min-width: 390px) and (max-width: 767px) {
    .footer-container {
        padding: 20px 12px;
        flex-direction: column;
        justify-content: space-between;
    }

    .footer-logo {
        margin: 0;
    }

    .footer-logo img {
        height: 50px;
        width: 90px;
    }

    .footer-logo p {
        font-size: 12px;
        margin-left: 2px;
    }

    .footer-links > div {
        margin: 18px 5px;
    }

    .footer-links {
        flex-direction: column;
        justify-content: space-between;
    }

    .footer-links div h3 {
        margin-bottom: 12px;
        font-size: 18px;
    }

    .footer-links div p {
        font-size: 10px;
    }

    .links a {
        font-size: 11px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .footer-container {
        padding: 20px 32px;
        flex-direction: column;
        justify-content: space-between;
    }

    .footer-logo {
        margin: 0;
    }

    .footer-logo img {
        height: 50px;
        width: 90px;
    }

    .footer-logo p {
        font-size: 12px;
        margin-left: 2px;
    }

    .footer-links > div {
        margin: 18px 5px;
    }

    .footer-links {
        flex-direction: column;
        justify-content: space-between;
    }

    .footer-links div h3 {
        margin-bottom: 12px;
        font-size: 20px;
    }

    .footer-links div p {
        font-size: 14px;
    }

    .links a {
        font-size: 11px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .footer-container {
        padding: 4%;
        flex-direction: row;
        justify-content: space-between;
    }

    .footer-logo {
        margin: 0;
    }

    .footer-logo img {
        height: 50px;
        width: 90px;
    }

    .footer-logo p {
        font-size: 12px;
        margin-left: 2px;
    }

    .footer-links > div {
        margin: 0;
    }

    .footer-links {
        flex-direction: row;
        justify-content: space-between;
        gap: 15px;

    }

    .footer-links div h3 {
        margin-bottom: 12px;
    }

    .footer-links div h3 {
        margin-bottom: 12px;
        font-size: 18px;
    }

    .footer-links div p {
        font-size: 10px;
    }

    .links a {
        font-size: 10px;
    }
}

@media screen and (min-width: 1280px) and (max-width: 1439px) {
    .footer-container {
        flex-direction: row;
        align-items: flex-start;
        padding: 64px 80px;
        justify-content: space-between;
    }

    .footer-logo img {
        width: 90px;
        height: 60px;
    }

    .footer-logo p {
        font-size: 12px;
        margin-left: 2px;
    }

    .footer-links {
        flex-direction: row;
        gap: 30px;
    }

    .footer-links div p {
        font-size: 11px;
    }

    .footer-links > div a {
        font-size: 10px;
    }

    .footer-links div h3 {
        margin-bottom: 18px;
        font-size: 18px;
    }

    .footer-links > div {
        margin: 0;
    }

    .footer-links .locations {
        margin: 0;
    }
}

@media screen and (min-width: 1440px) and (max-width: 1679px) {
    .footer-container {
        flex-direction: row;
        align-items: flex-start;
        padding: 64px 100px;
        justify-content: space-between;
    }

    .footer-logo img {
        width: 112px;
        height: 70px;
    }

    .footer-logo p {
        font-size: 16px;
        margin-left: 2px;
    }

    .footer-links {
        flex-direction: row;
        gap: 30px;
    }

    .footer-links > div p {
        font-size: 12px;
    }

    .footer-links > div a {
        font-size: 12px;
    }

    .footer-links > div h3 {
        margin-bottom: 20px;
        font-size: 22px;
    }

    .footer-links > div {
        margin: 0;
    }

    .footer-links .locations {
        margin: 0;
    }

    .footer-links > div:last-child {
        margin-left: 23px;
    }
}

@media screen and (min-width: 1680px) and (max-width: 1799px){
    .footer-container {
        flex-direction: row;
        align-items: flex-start;
        padding: 64px 120px;
        justify-content: space-between;
    }

    .footer-logo img {
        width: 132px;
        height: 80px;
    }

    .footer-logo p {
        font-size: 18px;
        margin-left: 2px;
    }

    .footer-links {
        flex-direction: row;
        gap: 40px;
    }

    .footer-links > div h3 {
        margin-bottom: 24px;
        font-size: 28px;
    }

    .footer-links > div p {
        font-size: 16px;
    }

    .footer-links > div a {
        font-size: 16px;
    }

    .footer-links > div {
        margin: 0;
    }

    .footer-links .locations {
        margin: 0;
    }

    .footer-links > div:last-child {
        margin-left: 23px;
    }
}

@media screen and (min-width: 1800px) {
    .footer-container {
        flex-direction: row;
        align-items: flex-start;
        padding: 64px 120px;
        justify-content: space-between;
    }

    .footer-logo img {
        width: 132px;
        height: 80px;
    }

    .footer-logo p {
        font-size: 18px;
        margin-left: 2px;
    }

    .footer-links {
        flex-direction: row;
        gap: 50px;
    }

    .footer-links > div h3 {
        margin-bottom: 24px;
        font-size: 28px;
    }

    .footer-links > div p {
        font-size: 16px;
    }

    .footer-links > div a {
        font-size: 16px;
    }

    .footer-links > div {
        margin: 0;
    }

    .footer-links .locations {
        margin: 0;
    }

    .footer-links > div:last-child {
        margin-left: 23px;
    }
}




